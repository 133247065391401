// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basket-js": () => import("./../../../src/pages/basket.js" /* webpackChunkName: "component---src-pages-basket-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-general-js": () => import("./../../../src/pages/general.js" /* webpackChunkName: "component---src-pages-general-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-archive-blog-js": () => import("./../../../src/templates/archive-blog.js" /* webpackChunkName: "component---src-templates-archive-blog-js" */),
  "component---src-templates-general-content-js": () => import("./../../../src/templates/general-content.js" /* webpackChunkName: "component---src-templates-general-content-js" */),
  "component---src-templates-product-archive-collection-js": () => import("./../../../src/templates/product-archive-collection.js" /* webpackChunkName: "component---src-templates-product-archive-collection-js" */),
  "component---src-templates-product-archive-template-js": () => import("./../../../src/templates/product-archive-template.js" /* webpackChunkName: "component---src-templates-product-archive-template-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */)
}

