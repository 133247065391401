module.exports = {
    title: `Konungr`,
    keywords: [
        "Konungr",
        "Shop",
        "Clothing",
        "Ethical",
    ],
    description: ``,
    author: "@codeandcreate",
    siteUrl: `https://konungr.co.uk`,
    cmsUrl: `https://konungr.myshopify.com`,
    trackingId: "UA-XXXXXXXX-1",
    defaultLang: "en",
    shopify: {
        domain: 'konungr.myshopify.com',
        key: '314c713f737ec5b42f0c2b6697b62de9'
    }
  };
